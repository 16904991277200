import React, {useEffect} from "react";
import {Button, notification} from "antd";
import {PieChartOutlined} from "@ant-design/icons";

import {setCookie, getCookie} from "src/enitities/Cookie";

export default function CookieAcceptNotification() {
  useEffect(() => {
    const cookieAccept = getCookie("cookieAccept");
    if (!cookieAccept) {
      notification.open({
        message: "Мы используем cookies",
        description:
          "Для ускорения работы с формами мы используем cookies. Продолжая пользоваться нашим сайтом, вы соглашаетесь с их использованием.",
        icon: <PieChartOutlined />,
        btn: (
          <>
            <Button type="primary" onClick={handleAccept} style={{ marginRight: 8 }}>
              Принять
            </Button>
            <Button onClick={handleDecline}>Отказаться</Button>
          </>
        ),
        duration: 0,
        placement: "bottomRight"
      });
    }
  }, []);

  const handleAccept = () => {
    setCookie("cookieAccept", "true", 365);
    notification.destroy();
  };

  const handleDecline = () => {
    setCookie("cookieAccept", "false", 365);
    notification.destroy();
  };

  return (
    <>
    </>
  );
};
